@font-face {
  font-family: 'Syncopate Bold';
  src: url('./assets/fonts/syncopate-bold.ttf') format('truetype')
}

@font-face {
  font-family: 'Signika Light';
  src: url('./assets/fonts/signika-light.ttf') format('opentype')
}

@font-face {
  font-family: 'Signika Regular';
  src: url('./assets/fonts/signika-regular.ttf') format('opentype')
}

body {
  margin: 0;
  font-family:'Syncopate Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

