.Background {
    background-image: url('/src/assets/images/background-all-color.jpg');
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
}

.Logo {
    margin-top: 10%;
    height: 40vmin;
}

.Title {
    color: white;
    font-family: 'syncopate-bold', sans-serif;
    font-size: 3em;
}

.Title-group {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    gap: 10px
}

.Connexion-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Error {
    background-color: #f8531b;
    border-radius: 30px;
    color: #ffffff;
    font-weight: bold;
    font-size: large;
    padding: 5px 20px 5px 20px;
    width: max-content;
}

.Env-style {
    color: #fbbf50;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 5px 10px;
    border-radius: 5px;
}